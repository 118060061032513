<template>
  <div class="home">
    <div id="head">
      <div class="logo">
        吾爱吾庐-简迹
      </div>
      <div class="buttons">
        <button class="home-button" @click="$router.push('/Home.md')">返回<br/>首页</button>
        <button class="top-button" onclick="goAnchor('head')">回到<br/>顶部</button>
        <span></span>
      </div>
    </div>
    <div v-html="content" :key="$route.fullPath" id="content"></div>
    <div id="foot">
      <span>浙ICP备17027825号-1</span>&ensp;
      <span>
      <a target=" blank "
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33078302100258"
        style="display: inline-block;
        text-decoration: none;
        height: 20px;
        line-height:
        20px;">
        <img
          src="/beianlogo.d0289dc.png"
          alt="beian"
          style="float: left;">浙公网安备 33078302100258号
      </a>&ensp;</span>
      <span style="height: 30px;"><a
              target=" blank "
              href="http://creativecommons.org/licenses/by-nc-nd/4.0/"
              rel="license">
        <img
          alt="知识共享协议"
          i="" src="/ccbyncnd.png"
          style="border-width: 0px; height: 30px; width: auto;">
      </a></span><span>&ensp;Copyleft</span>
      <span style="display: inline-block; transform: rotate(180deg);">&ensp;©&ensp;</span>
      <a href="https://jett.ink">jett zhu</a>
      <span>&ensp;{{ timestr }}&ensp;</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useRoute, onBeforeRouteUpdate } from 'vue-router';
import axios from 'axios';
import TyporaParser from 'typora-parser';
import HighlightJsRenderer from 'typora-parser/build/src/plugins/HighlightJsRenderer';
// import StubCodeRenderer from 'typora-parser/build/src/plugins/StubCodeRenderer';
import MathJaxRenderer from 'typora-parser/build/src/plugins/MathJaxRenderer';
// import StubLatexRenderer from 'typora-parser/build/src/plugins/StubLatexRenderer';
// import SimpleUrlResolver from 'typora-parser/build/src/plugins/SimpleUrlResolver';

const goAnchor = (id: string) => {
  // console.log(id);
  let anchor = document.getElementById(id);
  if (!anchor) {
    // anchor = document.getElementsByName(id)[0];
    [anchor] = document.getElementsByName(id);
  }
  try {
    if (!anchor) return;
    document.body.scrollTop = anchor.offsetTop;
    document.documentElement.scrollTop = anchor.offsetTop;
    window.scrollY = anchor.offsetTop;
  } finally {
    // do nothing
  }
};
// document.goAnchor = goAnchor;

const { params } = useRoute();
const content = ref('');
const timestr = ref('2023年');

const replaceHrefInHtml = (htmlStr: string) => {
  // 创建一个虚拟的 DOM 元素，用于解析 HTML 字符串
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlStr, 'text/html');

  // 获取所有以 '#' 开头的 <a> 标签
  const anchorTags = doc.querySelectorAll('a[href^="#"]');

  // 遍历每个匹配的 <a> 标签
  anchorTags.forEach((anchorTag) => {
    // 获取当前 href 属性的值
    const currentHref = anchorTag.getAttribute('href');
    if (!currentHref) return;
    // 删除 开头的 # 字符
    const newHref = currentHref.slice(1);
    // 添加 onclick 属性，调用 goAnchor 函数，参数为当前 href 的值
    anchorTag.setAttribute('onclick', `goAnchor('${newHref}')`);
    // 替换 href 属性为 javascript:void(0)
    // anchorTag.setAttribute('href', 'javascript:void(0)');
    anchorTag.removeAttribute('href');
  });

  // 将修改后的 DOM 转换回字符串
  const modifiedHtmlStr = new XMLSerializer().serializeToString(doc);

  return modifiedHtmlStr;
};

const getHtmlFromMd = (mdInput: string) => {
  const parseResult = TyporaParser.parse(mdInput);
  const html = parseResult.renderHTML({
    codeRenderer: new HighlightJsRenderer({
      displayLineNumbers: true,
    }),
    latexRenderer: new MathJaxRenderer(),
  });
  return replaceHrefInHtml(html);
};

onMounted(() => {
  console.log('mounted');
  console.log(params.path);
  setInterval(() => {
    // 更新时间到 timestr 年 月 日 时 分，月日时分均两位数
    const date = new Date();
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    const hour = `0${date.getHours()}`.slice(-2);
    const minute = `0${date.getMinutes()}`.slice(-2);
    timestr.value = `${year}年${month}月${day}日 ${hour}:${minute}`;
  }, 1000);
  let path = '';
  if (params.path) {
    path = params.path as string;
  } else {
    path = 'Home.md';
  }
  axios.get(`/api/${path}`).then((res) => {
    console.log(res.status);
    // console.log(res.data);
    try {
      content.value = getHtmlFromMd(res.data);
    } catch (e) {
      console.error(e);
    }
  });
});
onBeforeRouteUpdate((to, from) => {
  // console.log('before route update');
  console.log(to.params.path);
  if (to.params.path !== from.params.path) {
    axios.get(`/api/${to.params.path}`).then((res) => {
      console.log(res.status);
      console.log(res.data);
      try {
        content.value = getHtmlFromMd(res.data);
      } catch (e) {
        console.error(e);
      }
      goAnchor('head');
    });
  }
});
</script>

<style scoped>
#head {
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  /*
  background: #c0e6ffce;
  */
  background: rgba(133, 206, 255, 0.808);
  backdrop-filter: blur(3px);
  -webkit-mask-image: linear-gradient(to bottom, black 0, black 60%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 0, black 60%, transparent 100%);
  z-index: 100;
  display: flex;
  justify-content: space-between;
}
.logo {
  font-size: 30px;
  font-weight: bold;
  padding-left: 20px;
  padding-top: 10px;
    color:white;
    text-shadow:1px 1px black,-1px -1px black,1px -1px black,-1px 1px black;
}

.buttons {
  display: flex;
  gap: 10px; /* 可根据需要调整按钮之间的间距 */
}

.home-button,
.top-button {
  padding: 10px;
  background-color: #fff; /* 为按钮添加适当的样式 */
  border: none;
  cursor: pointer;
}

#foot {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40px;
  background-color: #ccc; /* 为了演示，您可以根据需要更改背景颜色 */
  text-align: center;
  line-height: 40px;
  display: flex;
  justify-content: center;
}

#content {
  /* 如果需要在 content 区域上方留出空间以显示 head，可以使用 padding-top */
  padding-top: 80px; /* 根据 head 的高度调整值 */
  /* 如果需要在 content 区域下方留出空间以显示 foot，可以使用 padding-bottom */
  padding-bottom: 50px; /* 根据 foot 的高度调整值 */
}

</style>
